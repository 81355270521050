import { useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Button, ButtonGroup, Col, OverlayTrigger, ProgressBar, Row, Spinner, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEye, faBook } from "@fortawesome/free-solid-svg-icons";
import JSZip from "jszip";

import StatementParseButton from "components/Statement/StatementParseButton";
import StatementProcessButton from "components/Statement/StatementProcessButton";
import StatementImportModal from "components/Statement/StatementImportModal";
import { useStatement } from "contexts/statement";
import { useNotification } from "contexts/notification";
import { encryptWithAES, decryptWithAES } from "utils/encrypt";

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16),
  );
}

const getZipItemFileName = (item, idx) => {
  if (!item) return `${uuidv4()}.pdf`;
  if (item.fileName?.length <= 100) return item.fileName;
  return `${`${idx + 1}`.padStart(3, "0")} ${item.createdAt.replace(/[-:\s]/g, "")}.pdf`;
};

const List = () => {
  const { id } = useParams();
  const {
    loading,
    mergeStatement,
    getStatementList,
    statementList,
    postStatementParse,
    postStatementProcess,
    exportAllStatementPages,
    exportAllStatementTransactions,
    postImportStatement,
  } = useStatement();

  const { pushNotification } = useNotification();
  const [zipLoading, setZipLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [importProcessing, setImportProcessing] = useState(false);
  const importFileRef = useRef(null);
  const [importStatementId, setImportStatementId] = useState(null);
  const [importDataPages, setImportDataPages] = useState([]);
  const [importDataTransactions, setImportDataTransactions] = useState([]);
  const [importModalShow, setImportModalShow] = useState(false);
  const [importProgress, setImportProgress] = useState(0);

  const getDetailList = useCallback(async () => {
    await getStatementList(id);
  }, []);

  useEffect(() => {
    getDetailList();
  }, []);

  const handleStatementParse = async (id) => {
    const res = await postStatementParse(id);

    if (res) await getDetailList();
  };

  const handleStatementProcess = async (id) => {
    const res = await postStatementProcess(id);

    if (res) await getDetailList();
  };

  const handleStatementExport = async (id) => {
    try {
      setExportLoading(true);

      const pages = await exportAllStatementPages(id);
      const transactions = await exportAllStatementTransactions(id);
      const json = JSON.stringify({ pages, transactions });
      const encrypted = encryptWithAES(json);

      const zip = new JSZip();
      zip.file("data.txt", encrypted);

      const blob = await zip.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `fn-exported-${Date.now()}.zip`;
      link.click();

      pushNotification("success", "Exported");
    } catch (e) {
      pushNotification("error", null, e);
    } finally {
      setExportLoading(false);
    }
  };

  const handleStatementImport = async (id) => {
    importFileRef.current.click();
    setImportStatementId(id);
  };

  const handleStatementImportConfirmation = async () => {
    try {
      if (!importStatementId) return;

      setImportProcessing(true);
      setImportModalShow(false);

      const pages = [...importDataPages];
      const transactions = [...importDataTransactions];

      await postImportStatement(importStatementId, pages, transactions, (progress) => {
        setImportProgress(progress);
      });
    } catch (err) {
      pushNotification("error", null, err);
    } finally {
      setImportProcessing(false);
    }
  };

  const handleImportFileChange = async (event) => {
    try {
      setImportLoading(true);
      setImportProgress(0);
      setImportDataPages([]);
      setImportDataTransactions([]);

      // Event target.
      const file = event.target.files[0];

      // Unzips and decrypts.
      const zip = new JSZip();
      const content = await file.arrayBuffer();
      const unzipped = await zip.loadAsync(content);
      const encryptedDataJson = await unzipped.file("data.txt").async("string");
      const decryptedDataJson = decryptWithAES(encryptedDataJson);
      const decryptedData = JSON.parse(decryptedDataJson);
      setImportDataPages(decryptedData?.pages);
      setImportDataTransactions(decryptedData?.transactions);

      setImportModalShow(true);
    } catch (e) {
      pushNotification("error", "Invalid Import Data");
    } finally {
      // Resets input file.
      if (importFileRef.current) {
        importFileRef.current.value = "";
      }

      setImportLoading(false);
    }
  };

  const handleDownloadZip = async () => {
    if (!mergeStatement) return;
    if (!statementList?.data?.length) return;

    try {
      setZipLoading(true);

      const zip = new JSZip();

      const fetchAndAddToZip = async (zip, filename, fileUrl) => {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        zip.file(filename, blob);
      };

      const promises = statementList.data.map((item, idx) =>
        fetchAndAddToZip(zip, getZipItemFileName(item, idx), item.fileUrl),
      );
      await Promise.all(promises);

      const content = await zip.generateAsync({ type: "blob", compression: "DEFLATE" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = `finskor-${mergeStatement.createdAt.replace(/[-:\s]/g, "")}.zip`;
      link.click();

      pushNotification("success", "Download Success");
    } catch (e) {
      pushNotification("error", "Download Failed", e);
    } finally {
      setZipLoading(false);
    }
  };

  const handleShowHITLGuidance = () => {
    window.open(statementList.meta.hitlLinkGuidance, "_blank");
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h4 className="mb-4">Statement List ({statementList.data.length})</h4>
        {statementList.meta?.hitlLinkGuidance && (
          <div>
            <Button onClick={handleShowHITLGuidance} variant="warning" size="sm">
              <FontAwesomeIcon icon={faBook} className="me-2" />
              HITL Guidance
            </Button>
          </div>
        )}
      </div>

      <input
        ref={importFileRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleImportFileChange}
        accept=".zip"
      />
      {importProcessing && <ProgressBar animated now={importProgress} max={100} className="mb-3" />}

      {loading ? (
        <Skeleton height={250} className="rounded" />
      ) : (
        <div className="d-flex flex-column align-items-center gap-4">
          <div className="w-100">
            <OverlayTrigger
              overlay={<Tooltip>Download All {statementList.data.length} Statement Files as Zip</Tooltip>}
              placement="right"
            >
              <Button onClick={handleDownloadZip} variant="outline-secondary" disabled={zipLoading}>
                {zipLoading && <FontAwesomeIcon icon={faCog} className="fa-spin fa-lg me-2" />}
                Download Zip
              </Button>
            </OverlayTrigger>
          </div>
          {statementList.data.map((statement, index) => (
            <div className="item rounded border border-secondary dashed w-100 py-4" key={statement.uuid}>
              <Row>
                <Col xs={1} className="d-flex justify-content-center align-items-center">
                  <p className="text-uppercase fw-bold">{index + 1}</p>
                </Col>
                <Col xs={8} className="border-start border-end px-4">
                  <Row>
                    <Col xs={2}>File Name</Col>
                    <Col xs={10} className="text-left">
                      <a href={statement.fileUrl} target="_blank" className="text-break" rel="noreferrer">
                        {statement.fileName}
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2}>Processed At</Col>
                    <Col xs={10} className="text-left">
                      {statement.processedAt}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2}>Created At</Col>
                    <Col xs={10} className="text-left">
                      {statement.createdAt}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2}>Updated At</Col>
                    <Col xs={10} className="text-left">
                      {statement.updatedAt}
                    </Col>
                  </Row>
                </Col>
                <Col xs={3} className="d-flex align-items-center justify-content-between px-5">
                  <ButtonGroup>
                    <OverlayTrigger overlay={<Tooltip>Open</Tooltip>}>
                      <Button size="sm" variant="link" className="px-1" as={Link} to={`../statement/${statement.uuid}`}>
                        <FontAwesomeIcon size="xl" icon={faEye} className="text-primary pointer-event" />
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                  <ButtonGroup vertical>
                    <ButtonGroup>
                      <StatementParseButton
                        handleCallback={() => handleStatementParse(statement.uuid)}
                        variant="outline-success"
                      />
                      <StatementProcessButton
                        handleCallback={() => handleStatementProcess(statement.uuid)}
                        variant="outline-primary"
                      />
                    </ButtonGroup>
                    <ButtonGroup>
                      <Button
                        variant="outline-secondary"
                        onClick={() => handleStatementExport(statement.uuid)}
                        disabled={exportLoading}
                      >
                        {exportLoading && (
                          <span className="mx-3">
                            <Spinner size="sm" animation="border" />
                          </span>
                        )}
                        {!exportLoading && <>Export</>}
                      </Button>
                      <Button
                        variant="outline-danger"
                        onClick={() => handleStatementImport(statement.uuid)}
                        disabled={importLoading}
                      >
                        {importLoading && (
                          <span className="mx-3">
                            <Spinner size="sm" animation="border" />
                          </span>
                        )}
                        {!importLoading && <>Import</>}
                      </Button>
                    </ButtonGroup>
                  </ButtonGroup>
                </Col>
              </Row>
              {statement.detailStatements.length > 0 && (
                <>
                  <hr className="my-3" />
                  <Row>
                    <Col className="px-5">
                      <Row className="py-1 fw-bold">
                        <Col xs={1} className="border-end text-center fw-bold">
                          #
                        </Col>
                        <Col xs={2} className="border-end">
                          Bank
                        </Col>
                        <Col className="border-end">Nama Rekening</Col>
                        <Col className="border-end">Nomor Rekening</Col>
                        <Col>Period</Col>
                      </Row>
                      {statement.detailStatements.map((detail, index) => (
                        <Row className="border-top py-1" key={index}>
                          <Col xs={1} className="border-end text-center">
                            {index + 1}
                          </Col>
                          <Col xs={2} className="border-end">
                            {detail.bankName}
                          </Col>
                          <Col className="border-end">{detail.bankAccountName}</Col>
                          <Col className="border-end">{detail.bankAccountNumber}</Col>
                          <Col>{detail.period}</Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </>
              )}
            </div>
          ))}

          <StatementImportModal
            show={importModalShow}
            handleConfirmationCallback={handleStatementImportConfirmation}
            handleCancellationCallback={() => setImportModalShow(false)}
            pages={importDataPages}
            transactions={importDataTransactions}
          />
        </div>
      )}
    </>
  );
};

export default List;

import { faArrowDown, faArrowUp, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomInput from "components/Common/CustomInput";
import { Button, ButtonGroup, Col, Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";

const tableHeader = (
  <tr>
    <th>#</th>
    <th style={{ width: "145px" }}>Timestamp</th>
    <th style={{ width: "145px" }}>Date</th>
    <th style={{ width: "30%" }}>Description</th>
    <th>Amount</th>
    <th>Ending Balance</th>
    <th style={{ width: "7.5%" }}>Position</th>
    <th style={{ width: "1%" }}></th>
  </tr>
);

const StatementPageTable = (props) => {
  const {
    transactionList,
    onDataChange,
    onAddTransaction,
    onRemoveTransaction,
    onMoveUpTransaction,
    onMoveDownTransaction,
  } = props;
  const { Control: Input, Select } = Form;

  return (
    <>
      <Table responsive bordered striped hover size="sm" className="mb-3">
        <thead>{tableHeader}</thead>
        <tbody>
          {transactionList.map((transaction, index) => (
            <tr key={index}>
              <td className="align-middle">{index + 1}</td>
              <td>
                <Input
                  as="textarea"
                  rows={2}
                  value={transaction.timestamp}
                  onChange={(e) => onDataChange(e, index, "timestamp")}
                  className={`form-control ${transaction.find_matches?.timestamp ? "highlight" : ""} 
                  ${!/^20\d{2}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(transaction.timestamp) ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback text-danger">Invalid!</div>
              </td>
              <td>{transaction.date}</td>
              <td>
                <Input
                  as="textarea"
                  rows={4}
                  value={transaction.description}
                  onChange={(e) => onDataChange(e, index, "description")}
                  className={transaction.find_matches?.description ? "highlight" : ""}
                />
                {transaction.tagging && (
                  <p className="mt-2 text-primary text-start">
                    <small>
                      {Object.entries(transaction.tagging)
                        .map(([key, value]) => `${key} = ${value}`)
                        .join(", ")}
                    </small>
                  </p>
                )}
                {transaction.categorizations?.length && (
                  <p className="text-success text-start">
                    <small>categorizations = {transaction.categorizations}</small>
                  </p>
                )}
              </td>
              <td style={{ textAlign: "right" }}>
                <CustomInput
                  type="text"
                  required
                  onChange={(e) => onDataChange(e, index, "amount")}
                  value={transaction.amount}
                  onlyAcceptNumbers
                  canAcceptDecimals
                  inputClassName="text-end"
                  iconPosition=""
                  maxDecimals={2}
                  placeholder={"0.00"}
                />
                <div className="m-2">
                  {Number(transaction.amount).toLocaleString("id-ID", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
                {transaction.category?.length > 0 && (
                  <p className="mt-2 text-primary text-start">
                    <small>category = {transaction.category}</small>
                  </p>
                )}
              </td>
              <td style={{ textAlign: "right" }}>
                <CustomInput
                  type="text"
                  required
                  onChange={(e) => onDataChange(e, index, "endingBalance")}
                  value={transaction.endingBalance}
                  onlyAcceptNumbers
                  canAcceptDecimals
                  inputClassName="text-end"
                  iconPosition=""
                  maxDecimals={2}
                  placeholder={"0.00"}
                />
                <div className="m-2">
                  {Number(transaction.endingBalance).toLocaleString("id-ID", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
                {transaction.diffEndingBalance && (
                  <div className="m-2 text-danger font-weight-bold">
                    <p>Before</p>
                    {Number(transaction.diffEndingBalance).toLocaleString("id-ID", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                )}
              </td>
              <td>
                <Select
                  value={transaction.position}
                  onChange={(e) => onDataChange(e, index, "position")}
                  className={!transaction.position ? "form-select is-invalid" : "form-select"}
                >
                  {!transaction.position && <option value=""></option>}
                  <option value="DB">DB</option>
                  <option value="CR">CR</option>
                </Select>
                <div className="invalid-feedback text-danger">Invalid!</div>
              </td>
              <td className="align-middle px-3">
                <ButtonGroup vertical>
                  <ButtonGroup>
                    <OverlayTrigger overlay={<Tooltip>Move Up</Tooltip>}>
                      <Button
                        variant="outline-secondary"
                        onClick={(e) => onMoveUpTransaction(e, index)}
                        disabled={index === 0}
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Add New</Tooltip>}>
                      <Button variant="outline-primary" onClick={(e) => onAddTransaction(e, index)}>
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                  <ButtonGroup>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Move Down</Tooltip>}>
                      <Button
                        variant="outline-secondary"
                        onClick={(e) => onMoveDownTransaction(e, index)}
                        disabled={index === transactionList.length - 1}
                      >
                        <FontAwesomeIcon icon={faArrowDown} />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Delete</Tooltip>}>
                      <Button variant="outline-danger" onClick={(e) => onRemoveTransaction(e, index)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {transactionList.length == 0 && (
        <Row className="mb-4">
          <Col>
            <Button variant="outline-primary" onClick={onAddTransaction}>
              Add Transaction
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default StatementPageTable;

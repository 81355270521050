import { useEffect, useState } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "contexts/auth";
import ModalBox from "components/Common/ModalBox";

const NavigationBar = () => {
  const { Brand, Toggle, Collapse } = Navbar;
  const { Link: NavLink } = Nav;
  const { Item: DropdownItem } = NavDropdown;
  const [openModal, setOpenModal] = useState(false);
  const [labelRedirect, setLabelRedirect] = useState("");
  const [modalProperties, setModalProperties] = useState({
    title: "",
    body: "",
    type: "",
  });
  const { user, logout } = useAuth();
  const path = window.location.pathname || "";
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
  };

  const setModalRedirect = (type) => {
    switch (type) {
      case "redirect-change-password":
        setOpenModal(true);
        setModalProperties({
          title: "Password Update Required",
          body: "Your password has expired and needs to be changed.",
          type,
        });
        setLabelRedirect("Redirect To Change Password Page");
        break;
      case "redirect-update-2fa":
        setOpenModal(true);
        setModalProperties({
          title: "Security Update Required",
          body: "Please review and update your Two-Factor Authentication (2FA) settings to ensure continued protection of your account. This will help safeguard against unauthorized access.",
          type,
        });
        setLabelRedirect("Redirect To Update 2FA Setting");
        break;
      default:
        break;
    }
  };

  const redirectToProfilePage = () => {
    if (modalProperties.type === "redirect-change-password") {
      navigate("/profile/change-password");
    } else if (modalProperties.type === "redirect-update-2fa") {
      navigate("/profile/two-factor-authentication");
    }
  };

  useEffect(() => {
    if (!path.includes("/profile")) {
      if (user && user.password_expired) {
        setModalRedirect("redirect-change-password");
      } else if (user && user.two_factor_authentication === "inactive") {
        setModalRedirect("redirect-update-2fa");
      }
    }
  }, [user]);

  const menus = [
    {
      activePath: "statement",
      label: "Rekening Koran",
      to: "/statement-dashboard",
      onClick: null,
    },
    {
      activePath: "slik",
      label: "SLIK",
      to: "/slik-dashboard",
      onClick: null,
    },
    {
      activePath: "pefindo",
      label: "Pefindo",
      to: "/pefindo-dashboard",
      onClick: null,
    },
    {
      activePath: "partner-log",
      label: "Partner Log",
      to: "/partner-log-dashboard",
      onClick: null,
    },
    {
      activePath: "partner-list",
      label: "Partner List",
      to: "/partner-list-dashboard",
      onClick: null,
    },
    {
      activePath: "finetuning-log",
      label: "Fine Tuning Log",
      to: "/finetuning-log-dashboard",
      onClick: null,
    },
    {
      activePath: null,
      label: "Internal",
      to: null,
      onClick: null,
      children: [
        {
          label: "Sandbox",
          onClick: (e) => {
            e.preventDefault();
            navigate("/sandbox");
          },
        },
        {
          separator: true,
        },
        {
          label: "Opstools",
          onClick: () => {
            const currentHost = window.location.hostname;

            let newHost;
            if (currentHost === "localhost") {
              newHost = "localhost:5173";
            } else {
              newHost = `opstools.${currentHost}`;
            }

            const timestamp = new Date().getTime();
            window.location.href = `${window.location.protocol}//${newHost}?t=${timestamp}`;
          },
        },
      ],
    },
  ];

  const menuNavLink = (menu, index) => {
    const { activePath, label, to, onClick, children } = menu;
    if (children?.length > 0) {
      return (
        <NavDropdown key={index} title={label}>
          {children.map((item, itemIndex) => {
            if (item.separator) {
              return <NavDropdown.Divider key={itemIndex} />;
            }

            return (
              <DropdownItem key={itemIndex} onClick={item.onClick}>
                {item.label}
              </DropdownItem>
            );
          })}
        </NavDropdown>
      );
    }

    return (
      <NavLink
        key={index}
        onClick={onClick}
        as={onClick ? undefined : Link}
        to={onClick ? undefined : to}
        className={{
          "fw-bold border-primary border-bottom border-3": path.includes(activePath),
          "ms-3": index > 0,
        }}
        active={path.includes(activePath)}
      >
        {label}
      </NavLink>
    );
  };

  return (
    <Navbar expand="lg" className="mb-4 shadow">
      <Container>
        <Brand href="/">
          <img alt="Logo" src="/img/logo.png" height="30" className="d-inline-block align-top" />
        </Brand>
        <Toggle />
        <Collapse className="justify-content-between">
          <Nav>{menus.map(menuNavLink)}</Nav>
          <Nav>
            <NavDropdown title={user.name}>
              <DropdownItem onClick={() => navigate("/profile")}>Profile</DropdownItem>
              <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
            </NavDropdown>
          </Nav>
        </Collapse>
        <ModalBox
          show={openModal}
          title={modalProperties.title}
          body={modalProperties.body}
          isShowNegativeClick={false}
          showCloseButtonModal={false}
          labelPositiveClick={labelRedirect}
          onPositiveClick={() => redirectToProfilePage()}
        />
      </Container>
    </Navbar>
  );
};

export default NavigationBar;

const { Row, Col, Table } = require("react-bootstrap");
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
/* 
  table header structure example

  const tableHeaders = [
    { key: "index", text: "#", style: {} },
    { key: "companyName", text: "Company Name", style: {} },
    { key: "name", text: "Partner Name", style: {} },
    { key: "email", text: "Email", style: {} },
    { key: "status", text: "Status", style: {} },
    { key: "lastLoginAt", text: "Last Login At", style: {} },
    { key: "createdAt", text: "Created At", style: {} },
    { key: "action", text: "Actions", style: {} },
  ];
 */

const PaginationDashboardTable = ({ onPageChange, list, limit, loading, headers, rows }) => {
  const [startCount, setStartCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  useEffect(() => {
    if (!list?.meta?.currentPage) return;
    const start = (list?.meta?.currentPage - 1) * limit + 1;
    setStartCount(start);
    setEndCount(start + (list?.meta?.totalDataPerPage - 1));
  }, [list]);

  const Pagination = () => {
    return (
      <Row>
        <Col>
          <small>
            Showing {startCount} - {endCount} from {list?.meta?.totalData} entries
          </small>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="pagination">
            <ReactPaginate
              breakLabel="..."
              pageCount={list?.meta?.totalPage}
              pageRangeDisplayed={5}
              previousLabel="<"
              nextLabel=">"
              onPageChange={onPageChange}
              forcePage={list?.meta?.currentPage - 1}
            />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Pagination />
      <Table responsive bordered striped hover size="sm" className="mb-4">
        <thead>
          <tr>
            {headers?.map((header, idx) => (
              <th key={`header-${idx}`} style={header?.style}>
                {header.text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            Array.from(Array(5).keys()).map((val1) => (
              <tr key={val1}>
                {Array.from(Array(headers?.length || 5).keys()).map((val2) => (
                  <td key={`${val1}${val2}`}>
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))
          ) : !rows ? (
            <tr>
              <td>Data not Found</td>
            </tr>
          ) : (
            rows.map((row, idx) => (
              <tr key={`row-${idx}`} className={row.className}>
                {headers?.map((a, i) => (
                  <td key={`col-${idx}-${i}`}>{row[a?.key]}</td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <Pagination />
    </>
  );
};

export default PaginationDashboardTable;

import Cookies from "js-cookie";

const authCookieName = "token-bo";

export const getCookie = () => Cookies.get(authCookieName) || "";

const getCookieDomain = () => {
  return process.env.REACT_APP_AUTH_COOKIE_DOMAIN || undefined;
};

export const setCookie = (token, expiryDate) => {
  const domain = getCookieDomain();
  Cookies.set(authCookieName, token, {
    expires: new Date(expiryDate),
    domain,
  });
};

export const removeCookie = () => {
  Cookies.remove(authCookieName);
  const domain = getCookieDomain();
  Cookies.remove(authCookieName, { domain });
};

import { useCallback, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row, Form, Button, ButtonGroup, Spinner } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import Breadcrumb from "components/Common/Breadcrumb";
import Footer from "components/Common/Footer";
import NavigationBar from "components/Common/NavigationBar";
import StatementPageTable from "components/Statement/StatementPageTable";
import StatementPageTableInfo from "components/Statement/StatementPageTableInfo";
import FindReplaceAll from "components/Common/FindReplace/FindReplaceAll";
import EditInformationStatementForm from "components/Statement/StatementPage/EditInformationForm";

import { getStatementPage as getStatementPageApi } from "utils/api";
import { getTodayDate } from "utils/date";
import { useNotification } from "contexts/notification";
import { useStatement } from "contexts/statement";
import { transformCameltoSnakeObject, transformCamelToSnakeInArray } from "utils/helper";
import "./index.scss";
import AutocompleteInput from "components/Common/Autocomplete/AutocompleteInput";

const transactionDefault = {
  timestamp: getTodayDate(true),
  // date: getTodayDate(false),
  description: "",
  amount: "0.00",
  endingBalance: "0.00",
  position: "DB",
};

const StatementPage = () => {
  const navigate = useNavigate();
  const { pushNotification } = useNotification();
  const [formData, setFormData] = useState(null);
  const [transactionList, setTransactionList] = useState([]);
  const { id } = useParams();
  const {
    loading,
    statementPage,
    statementTransactionList,
    getStatementPage,
    putStatementSavePage,
    getStatementTransactionList,
    putStatementSaveTransaction,
    parserModuleNameList,
    statementModuleNameList,
    getParserStatementModuleNameList,
  } = useStatement();
  const [commandLoading, setCommandLoading] = useState(false);
  const { Control: Input } = Form;

  // Edit Information
  const [editInformationForm, setEditInformationForm] = useState({
    opening_balance: 0,
    total_debit_amount: 0,
    total_credit_amount: 0,
    closing_balance: 0,
    fraud_analysis_ready: false,
  });

  const breadcrumbItems = [
    { link: `/merge-statement/${statementPage?.mergeUuid}`, text: `Merge Statement` },
    { link: `/statement/${statementPage?.statementUuid}`, text: "Statement" },
    { link: `/statement-page/${id}`, text: "Statement Page" },
  ];

  const findReplaceRef = useRef();

  const getDetail = useCallback(async (currentId) => {
    await getStatementPage(currentId);
  }, []);

  const getTransactionList = useCallback(async (currentId) => {
    await getStatementTransactionList(currentId);
  }, []);

  const handlePrevNextPage = async (cmd) => {
    try {
      setCommandLoading(true);
      const { data } = await getStatementPageApi(id, { cmd });
      if (data?.data?.uuid) {
        navigate(`/statement-page/${data?.data?.uuid}`);
      }
    } catch (err) {
      pushNotification("error", null, err);
    } finally {
      setCommandLoading(false);
    }
  };

  const handleCopyPageDetailToClipboard = async () => {
    try {
      const objectToCopy = {
        detail_form: JSON.stringify(formData),
        edit_information_form: JSON.stringify(editInformationForm),
      };
      const clipboardText = await navigator.clipboard.readText();
      let clipboardData;

      try {
        clipboardData = JSON.parse(clipboardText);
      } catch (error) {
        clipboardData = {};
      }

      if (clipboardData && clipboardData.detail_ds_form) {
        objectToCopy.detail_ds_form = clipboardData.detail_ds_form;
      } else {
        objectToCopy.detail_ds_form = null;
      }

      if (clipboardData && clipboardData.transaction) {
        objectToCopy.transaction = clipboardData.transaction;
      } else {
        objectToCopy.transaction = null;
      }
      const jsonData = JSON.stringify(objectToCopy);
      await navigator.clipboard.writeText(jsonData);
      pushNotification("success", "Copied");
    } catch (e) {
      pushNotification("error", null, e);
    }
  };

  const handlePastePageDetailFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const data = JSON.parse(text);

      if (data?.detail_form && data?.edit_information_form) {
        setFormData(JSON.parse(data.detail_form));
        setEditInformationForm(JSON.parse(data.edit_information_form));
        pushNotification("success", "Pasted");
      }
    } catch (e) {
      pushNotification("error", null, e.message || e);
    }
  };

  const handleAutoEndingBalance = () => {
    const transactions = transactionList;
    if (!(transactions?.length > 1)) return;

    try {
      let previousEndingBalance = Number(transactions[0].endingBalance);
      previousEndingBalance = Number.isNaN(previousEndingBalance) ? 0 : previousEndingBalance;

      let countDiff = 0;
      for (let i = 1; i < transactions.length; i++) {
        const transaction = transactions[i];
        let amount = Number(transaction.amount);
        amount = Number.isNaN(amount) ? 0 : amount;

        let endingBalance;
        if (transaction.position === "CR") {
          endingBalance = previousEndingBalance + amount;
        } else if (transaction.position === "DB") {
          endingBalance = previousEndingBalance - amount;
        }

        endingBalance = Number.isNaN(endingBalance) ? 0 : endingBalance;
        if (transaction.endingBalance !== endingBalance.toFixed(2).toString()) {
          transaction.diffEndingBalance = transaction.endingBalance;
          countDiff += 1;
        } else {
          delete transaction.diffEndingBalance;
        }

        transaction.endingBalance = endingBalance.toFixed(2).toString();
        previousEndingBalance = endingBalance;
      }

      setTransactionList([...transactions]);
      pushNotification("success", `Auto Ending Balance Completed (${countDiff} difference)`);
    } catch (e) {
      pushNotification("error", null, e);
    }
  };

  const handleCopyTransactionsToClipboard = async () => {
    try {
      const objectToCopy = {
        transaction_list: JSON.stringify(transactionList),
      };
      const clipboardText = await navigator.clipboard.readText();
      let clipboardData;

      try {
        clipboardData = JSON.parse(clipboardText);
      } catch (error) {
        clipboardData = {};
      }

      if (clipboardData && clipboardData.detail_ds_form) {
        objectToCopy.detail_ds_form = clipboardData.detail_ds_form;
      } else {
        objectToCopy.detail_ds_form = null;
      }

      if (clipboardData && clipboardData.detail_form) {
        objectToCopy.detail_form = clipboardData.detail_form;
      } else {
        objectToCopy.detail_form = null;
      }

      if (clipboardData && clipboardData.edit_information_form) {
        objectToCopy.edit_information_form = clipboardData.edit_information_form;
      } else {
        objectToCopy.edit_information_form = null;
      }
      const jsonData = JSON.stringify(objectToCopy);
      await navigator.clipboard.writeText(jsonData);
      pushNotification("success", "Copied");
    } catch (e) {
      pushNotification("error", null, e);
    }
  };

  const handlePasteTransactionsFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const data = JSON.parse(text);

      if (data?.transaction_list) {
        const list = JSON.parse(data.transaction_list);
        const list1 = list?.map((item) => ({
          ...item,
          position: ["DB", "CR"].includes(item.position) ? item.position : "",
        }));
        setTransactionList(list1);
        pushNotification("success", "Pasted");
      }
    } catch (e) {
      pushNotification("error", null, e);
    }
  };

  const handleInputChange = (e, type) => {
    setFormData({
      ...formData,
      [type]: ["engineParserModuleName", "engineStatementModuleName"].includes(type) ? e : e.target.value,
    });
  };

  const handleInputChangeInformation = (e, type, inputType = "value") => {
    setEditInformationForm({
      ...editInformationForm,
      [type]: inputType === "value" ? e.target.value : e.target.checked,
    });
  };

  const handleTransactionChange = (e, index, type) => {
    const selectedData = transactionList[index];

    if (selectedData) {
      if (["amount", "endingBalance"].includes(type)) {
        selectedData[type] = e.target.value || "0.00";
      } else {
        selectedData[type] = e.target.value;
      }

      transactionList.splice(index, 1, selectedData);
      setTransactionList([...transactionList]);
      findReplaceRef.current.handleFind();
    }
  };

  const handleAddTransaction = (e, index) => {
    e.preventDefault();
    const newList = transactionList;
    const current = newList[index];
    if (current) {
      newList.splice(index + 1, 0, { ...transactionDefault, timestamp: current.timestamp });
    } else {
      newList.splice(index + 1, 0, { ...transactionDefault });
    }

    setTransactionList([...newList]);
  };

  const handleRemoveTransaction = (e, selectedIndex) => {
    e.preventDefault();
    setTransactionList([...transactionList.filter((_, index) => index !== selectedIndex)]);
  };

  const handleMoveUpTransaction = (e, index) => {
    e.preventDefault();
    if (index > 0) {
      const newList = [...transactionList];
      const temp = newList[index];
      newList[index] = newList[index - 1];
      newList[index - 1] = temp;
      setTransactionList(newList);
    }
  };

  const handleMoveDownTransaction = (e, index) => {
    e.preventDefault();
    if (index < transactionList.length - 1) {
      const newList = [...transactionList];
      const temp = newList[index];
      newList[index] = newList[index + 1];
      newList[index + 1] = temp;
      setTransactionList(newList);
    }
  };

  const handleInfoSave = async () => {
    const payloadForm = {
      ...formData,
      information: {
        opening_balance: Number(editInformationForm.opening_balance),
        total_debit_amount: Number(editInformationForm.total_debit_amount),
        total_credit_amount: Number(editInformationForm.total_credit_amount),
        closing_balance: Number(editInformationForm.closing_balance),
        fraud_analysis_ready: editInformationForm.fraud_analysis_ready,
      },
    };
    const res = await putStatementSavePage(id, transformCameltoSnakeObject(payloadForm));
    if (res) await getDetail(id);
  };

  const handleTransactionSave = async () => {
    const filteredTransactions = transactionList.map((modifiedData) => {
      if (modifiedData.find_matches) delete modifiedData.find_matches;
      return modifiedData;
    });

    const res = await putStatementSaveTransaction(id, transformCamelToSnakeInArray(filteredTransactions));
    if (res) await getTransactionList(id);
  };

  const handleFilterParserStatementModuleName = async (search, type) => {
    await getParserStatementModuleNameList({ search, type });
  };

  useEffect(() => {
    getDetail(id);
    getTransactionList(id);
  }, [id]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      bankName: statementPage?.bankName,
      bankAccountNumber: statementPage?.bankAccountNumber,
      bankAccountName: statementPage?.bankAccountName,
      companyName: statementPage?.companyName,
      period: statementPage?.period,
      engineParserModuleName: statementPage?.engineParserModuleName,
      engineStatementModuleName: statementPage?.engineStatementModuleName,
    }));
  }, [statementPage]);

  useEffect(() => {
    setTransactionList([...statementTransactionList.data]);
  }, [statementTransactionList]);

  const PrevNextButton = () => {
    return loading ? (
      <Skeleton width={250} height={30} />
    ) : (
      <div className="d-flex align-items-center">
        <Button
          size="sm"
          variant="outline-secondary"
          as={Link}
          onClick={() => handlePrevNextPage("prev")}
          className="mx-2"
          disabled={commandLoading}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Prev Page
        </Button>
        <p className="px-2">{statementPage?.pageNumber || "-"}</p>
        <Button
          size="sm"
          variant="outline-secondary"
          as={Link}
          onClick={() => handlePrevNextPage("next")}
          className="mx-2"
          disabled={commandLoading}
        >
          Next Page <FontAwesomeIcon icon={faArrowRight} />
        </Button>
        {commandLoading && <Spinner animation="border" variant="primary" />}
      </div>
    );
  };

  return (
    <>
      <NavigationBar />
      <Breadcrumb items={breadcrumbItems} />
      <Container style={{ marginBottom: "50px" }}>
        <div className="info">
          <h4 className="mb-4">Statement Page Detail</h4>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Page Number</p>
            </Col>
            <Col xs={4} className="text-left">
              <PrevNextButton />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Created At</p>
            </Col>
            <Col xs={4} className="text-left">
              {loading ? <Skeleton /> : <p>{statementPage?.createdAt || "-"}</p>}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Updated At</p>
            </Col>
            <Col xs={4} className="text-left">
              {loading ? <Skeleton /> : <p>{statementPage?.updatedAt || "-"}</p>}
            </Col>
          </Row>
          <hr />
          <Row className="mb-2">
            <Col xs={2}>
              <p>Bank Name</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <Input
                  type="text"
                  value={formData?.bankName || ""}
                  onChange={(e) => handleInputChange(e, "bankName")}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Bank Account Name</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <Input
                  type="text"
                  value={formData?.bankAccountName || ""}
                  onChange={(e) => handleInputChange(e, "bankAccountName")}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Bank Account Number</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <Input
                  type="text"
                  value={formData?.bankAccountNumber || ""}
                  onChange={(e) => handleInputChange(e, "bankAccountNumber")}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Company Name</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <Input
                  type="text"
                  value={formData?.companyName || ""}
                  onChange={(e) => handleInputChange(e, "companyName")}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Period</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <Input type="text" value={formData?.period || ""} onChange={(e) => handleInputChange(e, "period")} />
              )}
            </Col>
          </Row>
          <hr />
          <Row className="mb-2">
            <Col xs={2}>
              <p>Parser Name</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <AutocompleteInput
                  defaultValue={formData?.engineParserModuleName}
                  listData={parserModuleNameList.data}
                  getData={handleFilterParserStatementModuleName}
                  type="parser"
                  propertyName="moduleName"
                  displayPropertyName="moduleName"
                  inputPlaceholder="Search for parser module name..."
                  onChangeValue={(value) => handleInputChange(value, "engineParserModuleName")}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Statement Name</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <AutocompleteInput
                  defaultValue={formData?.engineStatementModuleName}
                  listData={statementModuleNameList.data}
                  getData={handleFilterParserStatementModuleName}
                  type="statement"
                  propertyName="moduleName"
                  displayPropertyName="displayStatementModuleName"
                  inputPlaceholder="Search for statement module name..."
                  onChangeValue={(value) => handleInputChange(value, "engineStatementModuleName")}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={2}>
              <p>Information</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton height={250} />
              ) : (
                <Input
                  as="textarea"
                  value={JSON.stringify(statementPage?.information, null, 2)}
                  rows={10}
                  readOnly
                  disabled
                />
              )}
            </Col>
          </Row>
          <hr />
          <EditInformationStatementForm
            statementPage={statementPage}
            setEditInformationForm={setEditInformationForm}
            loading={loading}
            handleInputChangeInformation={handleInputChangeInformation}
            editInformationForm={editInformationForm}
          />
          <Row className="mb-2">
            <Col xs={{ offset: 2, span: 6 }} className="d-flex justify-content-between">
              <ButtonGroup className="">
                <Button variant="outline-secondary" onClick={() => handleCopyPageDetailToClipboard()}>
                  Copy to Clipboard
                </Button>
                <Button variant="outline-success" onClick={() => handlePastePageDetailFromClipboard()}>
                  Paste From Clipboard
                </Button>
              </ButtonGroup>
              <ButtonGroup className="">
                <Button variant="outline-primary" onClick={handleInfoSave} disabled={loading}>
                  Save
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </div>
        <hr />
        <div className="transactions">
          <div className="d-flex mb-4">
            <div className="me-auto">
              <h4>Transaction List</h4>
            </div>

            <div className="d-flex gap-3 justify-content-end align-items-center">
              <ButtonGroup className="me-2">
                <Button variant="outline-secondary" onClick={() => handleAutoEndingBalance()}>
                  Auto Ending Balance
                </Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button variant="outline-secondary" onClick={() => handleCopyTransactionsToClipboard()}>
                  Copy to Clipboard
                </Button>
                <Button variant="outline-success" onClick={() => handlePasteTransactionsFromClipboard()}>
                  Paste From Clipboard
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <FindReplaceAll
            key={transactionList}
            data={transactionList}
            setData={setTransactionList}
            includePropertyToFind={["timestamp", "description"]}
            ref={findReplaceRef}
            loading={loading}
          />
          <StatementPageTable
            transactionList={transactionList}
            onDataChange={handleTransactionChange}
            onAddTransaction={handleAddTransaction}
            onRemoveTransaction={handleRemoveTransaction}
            onMoveUpTransaction={handleMoveUpTransaction}
            onMoveDownTransaction={handleMoveDownTransaction}
          />
          <StatementPageTableInfo onSave={handleTransactionSave} />
        </div>
        <div className="d-flex justify-content-center">
          {loading ? <Skeleton width={250} height={30} /> : <PrevNextButton />}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default StatementPage;

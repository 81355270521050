import { createContext, useContext, useState } from "react";
import { useNotification } from "./notification";
import { getAutoCompletePartnerCompanyList, getAutoCompletePartnerList, getPartnerList } from "utils/api";
import { transformSnakeToCamelInArray } from "utils/helper";

const paginationListDefault = {
  data: [],
  meta: {
    totalPage: 0,
    totalData: 0,
    totalDataPerPage: 0,
    currentPage: 0,
  },
};

const simpleListDefault = {
  data: [],
  meta: {
    totalData: 0,
  },
  default: true,
};

const responseDefault = {
  type: null,
  status: false,
  message: null,
  data: null,
};

export const PartnerListContext = createContext(null);

export const usePartnerList = () => {
  const ctx = useContext(PartnerListContext);

  if (!ctx) {
    throw new Error("usePartnerList must be used within the PartnerListProvider");
  }

  return ctx;
};

const PartnerListProvider = ({ children }) => {
  const { pushNotification } = useNotification();

  const [response, setResponse] = useState(responseDefault);
  const [loading, setLoading] = useState(false);
  const [partnerList, setPartnerList] = useState(paginationListDefault);
  const [autoCompletePartnerList, setAutoCompletePartnerList] = useState(simpleListDefault);
  const [autoCompletePartnerCompanyList, setAutoCompletePartnerCompanyList] = useState(simpleListDefault);

  const handleGetAutoCompletePartnerList = async (params = { search: "", limit: "", uuid: "" }) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "PARTNER_LIST",
    }));

    // api call
    try {
      const res = await getAutoCompletePartnerList(params);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setAutoCompletePartnerList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
          },
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetAutoCompletePartnerCompanyList = async (params = { search: "", limit: "" }) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "PARTNER_COMPANY_LIST",
    }));

    // api call
    try {
      const res = await getAutoCompletePartnerCompanyList(params);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setAutoCompletePartnerCompanyList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
          },
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetPartnerList = async (params = { search: "", limit: "", uuid: "" }) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "PARTNER_LIST",
    }));

    setLoading(true);
    // api call
    try {
      const res = await getPartnerList(params);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setPartnerList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
            totalDataPerPage: res.data.meta.total_data || params.limit,
            currentPage: params.page,
          },
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  return (
    <PartnerListContext.Provider
      value={{
        loading,
        response,
        partnerList,
        autoCompletePartnerList,
        autoCompletePartnerCompanyList,

        getPartnerList: handleGetPartnerList,
        getAutoCompletePartnerList: handleGetAutoCompletePartnerList,
        getAutoCompletePartnerCompanyList: handleGetAutoCompletePartnerCompanyList,
      }}
    >
      {children}
    </PartnerListContext.Provider>
  );
};

export default PartnerListProvider;

import { faChevronDown, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AutocompleteInput from "components/Common/Autocomplete/AutocompleteInput";
import Footer from "components/Common/Footer";
import NavigationBar from "components/Common/NavigationBar";
import PaginationDashboardTable from "components/Common/PaginationDashboardTable";
import { usePartnerList } from "contexts/partner_list";
import { useCallback, useEffect, useState } from "react";
import { Badge, Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { createSearchParams, Link, useLocation, useNavigate } from "react-router-dom";
import { transformSnakeToCamelInArray } from "utils/helper";
import { getAutoCompletePartnerList as getAutoCompletePartnerListApi } from "utils/api";

const defaultParams = {
  page: 1,
  limit: 25,
  partner_uuid: "",
  partner_company: "",
};

const tableHeaders = [
  { key: "index", text: "#", style: {} },
  { key: "companyName", text: "Company Name", style: {} },
  { key: "name", text: "Partner Name", style: {} },
  { key: "email", text: "Email", style: {} },
  { key: "status", text: "Status", style: {} },
  { key: "lastLoginAt", text: "Last Login At", style: {} },
  { key: "createdAt", text: "Created At", style: {} },
  { key: "action", text: "Actions", style: {} },
];

const statusBadge = (status) => {
  let bg = "";
  let color = "";

  switch (status) {
    case "approved":
      bg = "success";
      break;
    case "pending":
      bg = "danger";
      break;
    default:
      bg = "secondary";
  }

  return (
    <Badge bg={bg} className={color} pill>
      {status}
    </Badge>
  );
};

const Status = ({ status }) => {
  return <span className="text-capitalize">{status ? statusBadge(status) : ""}</span>;
};

const Action = ({ uuid }) => {
  /* TODO :
    - add action onclick to redirect to details page
    - doublecheck redirect route
  */

  const targetLink = `../partner-list/${uuid}`;
  return (
    <OverlayTrigger overlay={<Tooltip>Open</Tooltip>}>
      <Button variant="link" className="px-1" as={Link} to={targetLink}>
        <FontAwesomeIcon icon={faEye} className="text-primary pointer-event" />
      </Button>
    </OverlayTrigger>
  );
};

const Dashboard = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedCompanyFilter, setSelectedCompanyFilter] = useState("");
  const [transformedPartnerList, setTransformedPartnerList] = useState([]);
  const [trackState, setTrackState] = useState("");

  // Gets default value from query params.
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  if (!Number.isNaN(Number.parseInt(page, 10)) && page > 0) {
    defaultParams.page = page;
  }

  const selectedPartnerUUID = queryParams.get("partner");
  const selectedPartnerCompany = queryParams.get("company");

  defaultParams.partner_company = selectedPartnerCompany;
  defaultParams.partner_uuid = selectedPartnerUUID;

  const [params, setParams] = useState(defaultParams);

  const {
    loading,
    partnerList,
    getPartnerList,
    getAutoCompletePartnerList,
    autoCompletePartnerList,
    getAutoCompletePartnerCompanyList,
    autoCompletePartnerCompanyList,
  } = usePartnerList();

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getList = useCallback(
    async (newParams = {}, displayFilter = "") => {
      const tempParams = params;

      const mergedParams = { ...params, ...newParams };
      setParams(mergedParams);
      setSelectedFilter(displayFilter || "");
      setSelectedCompanyFilter(newParams.partner_company || "");

      await getPartnerList({
        ...tempParams,
        ...newParams,
      });
    },
    [params],
  );

  const getSelectedFilters = useCallback(
    async (uuid = null, companyName, queryPage) => {
      let partnerData;
      if (uuid) {
        const response = await getAutoCompletePartnerListApi({ uuid });

        if (response.data.data && response.data.data.length === 1) {
          const data = transformSnakeToCamelInArray(response.data.data);
          partnerData = data[0];
        }
      }
      getList(
        {
          partner_uuid: partnerData?.uuid || "",
          partner_company: companyName || "",
          page: queryPage || 1,
        },
        partnerData?.displayFilter,
      );
      setTrackState("filtered");
      setIsCollapsed(false);
    },
    [params],
  );

  const handleFilterAutocompletePartnerList = async (search) => {
    await getAutoCompletePartnerList({ search });
  };

  const handleFilterAutocompletePartnerCompanyList = async (search) => {
    await getAutoCompletePartnerCompanyList({ search });
  };

  const handleInputChange = (value, type) => {
    const searchParams = new URLSearchParams(location.search);
    if (type === "partner_uuid") {
      searchParams.set("partner", value.uuid);
      setSelectedFilter(value.displayFilter);
    }
    if (type === "partner_company") {
      searchParams.set("company", value.companyName);
      setSelectedCompanyFilter(value.companyName);
    }
    searchParams.set("page", 1);
    setParams({
      ...params,
      page: 1,
    });
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams(searchParams)}`,
    };
    setTrackState("filtered");
    navigate(options);
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", newPage);
    setParams({
      ...params,
      page: newPage,
    });
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams(searchParams)}`,
    };
    setTrackState("filtered");
    navigate(options);
  };

  const resetFilter = () => {
    navigate("/partner-list-dashboard");
    getList({
      partner_uuid: "",
      page: 1,
      partner_company: "",
    });
    defaultParams.page = 1;
    defaultParams.partner_uuid = "";
    defaultParams.partner_company = "";
    setSelectedFilter("");
    setTrackState("");
    setSelectedCompanyFilter("");
    setIsCollapsed(true);
  };

  const transformData = (partnerList) => {
    const newList = [];
    partnerList.forEach((partner, index) => {
      newList.push({
        ...partner,
        index: index + 1,
        companyName: partner.companyName ? partner.companyName : "N/A",
        status: <Status status={partner.status} />,
        action: <Action uuid={partner.uuid} />,
      });
    });
    setTransformedPartnerList(newList);
  };

  useEffect(() => {
    if (!selectedPartnerUUID && !selectedPartnerCompany && !trackState) {
      const queryParams = new URLSearchParams(location.search);
      const newPage = queryParams.get("page");
      if (!Number.isNaN(Number.parseInt(newPage, 10)) && newPage > 0) {
        defaultParams.page = newPage;
      }
      getList({
        partner_uuid: "",
        page: newPage || 1,
        partner_company: "",
      });
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const newPage = queryParams.get("page");
    if (!Number.isNaN(Number.parseInt(newPage, 10)) && newPage > 0) {
      defaultParams.page = newPage;
    }
    if (selectedPartnerUUID || selectedPartnerCompany) {
      getSelectedFilters(selectedPartnerUUID, selectedPartnerCompany, newPage);
    } else if (trackState) {
      getList({
        partner_uuid: "",
        page: newPage || 1,
        partner_company: "",
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (!partnerList) return;
    transformData(partnerList.data);
  }, [partnerList]);

  return (
    <>
      <NavigationBar />
      <Container style={{ marginBottom: "50px" }}>
        <div className="d-flex align-items-center mb-4">
          <h2 className="mb-0">Partner List</h2>
          <div className="ms-auto d-flex align-items-center">
            <button onClick={handleCollapseToggle} className="btn btn-outline-primary text-sm ms-3">
              Filter <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronDown} className="ms-2" />
            </button>
          </div>
        </div>
        <div className={`${isCollapsed ? "d-none" : "d-block mb-4"}`}>
          <div className="d-flex">
            <label style={{ width: "90px" }} className="my-auto">
              Partner
            </label>
            <AutocompleteInput
              key={selectedFilter}
              defaultValue={selectedFilter}
              listData={autoCompletePartnerList.data}
              getData={handleFilterAutocompletePartnerList}
              type="partner"
              propertyName="displayFilter"
              displayPropertyName="displayFilter"
              inputPlaceholder="Filter by partner name / partner email / partner company"
              onChangeValue={(value) => handleInputChange(value, "partner_uuid")}
              getAllPropertyInSelectedData
            />
          </div>

          <div className="d-flex my-3">
            <label style={{ width: "90px" }} className="my-auto">
              Company
            </label>
            <AutocompleteInput
              key={selectedCompanyFilter}
              defaultValue={selectedCompanyFilter}
              listData={autoCompletePartnerCompanyList.data}
              getData={handleFilterAutocompletePartnerCompanyList}
              type="partner"
              propertyName="companyName"
              displayPropertyName="companyName"
              inputPlaceholder="Filter by company name"
              onChangeValue={(value) => handleInputChange(value, "partner_company")}
              getAllPropertyInSelectedData
            />
          </div>

          <div className="text-end">
            <button onClick={() => resetFilter()} className="btn btn-outline-primary w-25">
              Reset Filter
            </button>
          </div>
        </div>
        <PaginationDashboardTable
          limit={params.limit}
          list={partnerList}
          loading={loading}
          onPageChange={handlePageChange}
          headers={tableHeaders}
          rows={transformedPartnerList}
        />
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
